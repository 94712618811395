import React from 'react'

const Council = () => {
    return (
        <div className='flex justify-center items-center min-h-[100vh] bg2'>
            <div className='bg-[] relative'>
                <div className='absolute example overflow-auto min-h-[75%] max-h-[75%] w-[80%] top-[15%] p-[2%]  left-1/2 transform -translate-x-1/2  bg-[]'>
                    <div className='my-4'>
                        <p className='text-3xl font-bold text-yellow-500'>Council of Chosen</p>
                        <img className='w-full opacity-70' src={require('../../Assets/ch8.jpg')} />
                        <p className='text-md my-4 text-yellow-200'>The Council of Doge is rolling out weekly Dogecasts to keep you in the loop—because who better to guide the future of @DOGE than a group of savvy pups with a knack for transparency and a taste for adventure? Get ready for updates, insights, and a few barks along the way. Tune in, or risk being left behind in the doghouse! 🐾🎙️ #Dogecoin #Dogecasts</p>
                    </div>
                </div>
                <img className='max-h-[90vh]' src={require('../../Assets/page2.png')} />
            </div>
        </div>
    )
}

export default Council