import React from 'react'

const Declaration = ({enter, setEnter}) => {
  return (
    <div className='h-[100vh] w-[100vw] bg1 flex justify-center items-center'>
      <div className='relative flex justify-center items-center'>
        <img className='ancient-card  w-[98%] md:w-[70%] xl:w-[50%] h-[80%] max-h-[90%]' src={require('../../Assets/dec.png')} />
        <button onClick={() => setEnter(!enter)} className='absolute bottom-[20%] left-1/2 transform -translate-x-1/2  text-white text-[20px] leading-[20px] bg-[#3B1616] border-[4px] border-[#b0a116] rounded-lg md:px-4 px-[10px] py-[6px] md:py-2 flex items-center font-bold'>
          I'm in
        </button>
      </div>
    </div>
  )
}

export default Declaration