import { useEffect, useState } from 'react';
import './App.css';
import Declaration from './Pages/Decleration/Declaration';
import Home from './Pages/Home/Home';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Council from './Pages/Council/Council';
import Purpose from './Pages/Purpose/Purpose';
const bgAudio = require('./Assets/bg.mp3')

function App() {
  const [enter, setEnter] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);

  // useEffect(() => {
  //   const audio = new Audio('/bg.mp3');  // Correct path to the public folder audio file

  //   // Mute the audio initially to comply with autoplay restrictions
  //   audio.muted = true;

  //   // Try playing the audio
  //   audio.play()
  //     .then(() => {
  //       console.log("Audio started playing...");

  //       // After a short delay (e.g., 5 seconds), unmute the audio
  //       setTimeout(() => {
  //         console.log("Unmuting audio...");
  //         audio.muted = false;  // Unmute after the delay
  //       }, 3000);  // Unmute after 5 seconds
  //     })
  //     .catch((error) => {
  //       console.error("Audio play failed:", error);  // Log any error with playing the audio
  //     });

  //   // Cleanup when the component unmounts
  //   return () => {
  //     audio.pause();           // Stop the audio
  //     audio.currentTime = 0;   // Reset audio to the beginning
  //     console.log("Audio cleaned up.");
  //   };
  // }, []);
  useEffect(() => {
    const audio = new Audio('/bg.mp3'); // Correct path to your public folder audio file

    // Mute the audio initially to comply with autoplay restrictions
    audio.muted = true;

    // Start playing the audio (this works because it's muted)
    audio.play().then(() => {
      console.log("Audio started playing...");

      // After 5 seconds, unmute the audio (this is your desired behavior)
      setTimeout(() => {
        console.log("Unmuting audio...");
        audio.muted = false; // Unmute the audio
      }, 5000); // Delay of 5 seconds before unmuting
    }).catch((error) => {
      console.error("Audio play failed:", error);  // Log any error
    });

    // Enable looping
    audio.loop = true;

    // Cleanup when the component unmounts
    return () => {
      audio.pause();          // Stop the audio
      audio.currentTime = 0;  // Reset audio to the beginning
      console.log("Audio cleaned up.");
    };
  }, []);  

  return (
    <div className='max-h-[100vh] max-w-[100vw] overflow-hidden'>
      {
        !enter ? (
          <Declaration enter={enter} setEnter={setEnter} />
        ) : (
          <Router>
            <div>
              <nav className='fixed z-10 top-0 left-0 bg- w-[100vw] h-[16vh] flex flex-col md:flex-row justify-center items-center '>
                <ul className=' flex justify-center items-center gap-1 md:gap-4'>
                  <li>
                    <Link to="/">
                      <img className='h-[50px]' src={require('./Assets/hb.png')} />
                    </Link>
                  </li>
                  <li className='md:block hidden'>
                    <Link to="/council">
                      <img className='h-[50px]' src={require('./Assets/cc.png')} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/purpose">
                      <img className='h-[50px]' src={require('./Assets/ep.png')} />
                    </Link>
                  </li>
                </ul>
                <ul className=' flex justify-center items-center gap-1 md:gap-4 md:hidden'>
                  
                  <li>
                    <Link to="/council">
                      <img className='h-[50px]' src={require('./Assets/cc.png')} />
                    </Link>
                  </li>
               
                </ul>
              </nav>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/council" element={<Council />} />
                <Route path="/purpose" element={<Purpose />} />
              </Routes>
              <nav className='fixed z-10 bottom-0 left-0 bg- w-[100vw] h-[10vh] flex justify-center items-center '>
                <ul className=' flex justify-center items-center gap-1 md:gap-4'>
                  
                  <li>
                    <Link to="https://x.com/BookofDoge_ERC">
                      <img className='h-[50px]' src={require('./Assets/x.png')} />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://t.me/BookOfDogeBOD">
                      <img className='h-[50px]' src={require('./Assets/t.png')} />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </Router>
        )
      }
    </div>
  );
}

export default App;
