import React from 'react'

const Home = () => {
    return (
        <div className='flex justify-center items-center min-h-[100vh] bg2'>
            <div className='bg-[] relative'>
                <div className='absolute example overflow-auto min-h-[75%] max-h-[75%] w-[80%] top-[15%] p-[2%]  left-1/2 transform -translate-x-1/2  bg-[]'>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 1: The Genesis of Dogecoin</p>
                        <img className='w-full opacity-70' src={require('../../Assets/ch1.jpg')} />
                        <p className='text-md my-4'>In the early days of crypto mania, two developers, Billy and Jackson, decided to ape into the scene with something unconventional. They took the infamous Doge meme and, on a whim, created Dogecoin—a playful gamble in a world dominated by serious Bitcoin maximalists. The crypto community, always hungry for the next moonshot, saw potential in this lighthearted coin. Traders with diamond hands piled in, donning their virtual moon boots, ready for lift-off. Then came Elon Musk, the maestro of market moves, who started dropping tweets that sent Dogecoin's value rocketing. As the price surged, some jeeted out for quick gains, but the true OGs held firm, believing Dogecoin was more than a meme—it was a revolution fueled by community spirit and the wild energy of the crypto cosmos.</p>
                    </div>
                    {/* <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 2: The Sacred Mechanics of Dogecoin</p>
                        <img className='w-full opacity-90' src={require('../../Assets/ch2.png')} />
                        <p className='text-md my-4'>And lo, Dogecoin, the coin of the people, was bestowed with divine architecture. As written in the blockchain scrolls, it operates through the holy Proof-of-Work, where the chosen miners toil to uphold the ledger of truth. Unlike Bitcoin, bound by scarcity, Dogecoin flows endlessly, gifting the faithful with 10,000 coins every minute—a testament to its generosity. Its block times, a mere minute, are a blessing of speed, ensuring that no transaction lingers in purgatory. Thus, Dogecoin remains abundant, swift, and ever-resilient, a beacon for those who embrace its boundless energy.</p>
                    </div> */}
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 2: The Sacred Mechanics of Dogecoin</p>
                        <img className='w-full opacity-90' src={require('../../Assets/ch2.png')} />
                        <p className='text-md my-4'>And lo, Dogecoin, the coin of the people, was bestowed with divine architecture. As written in the blockchain scrolls, it operates through the holy Proof-of-Work, where the chosen miners toil to uphold the ledger of truth. Unlike Bitcoin, bound by scarcity, Dogecoin flows endlessly, gifting the faithful with 10,000 coins every minute—a testament to its generosity. Its block times, a mere minute, are a blessing of speed, ensuring that no transaction lingers in purgatory. Thus, Dogecoin remains abundant, swift, and ever-resilient, a beacon for those who embrace its boundless energy.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 3: The People of Doge</p>
                        <img className='w-full opacity-70' src={require('../../Assets/chn.png')} />
                        <p className='text-md my-4'>And it was written that the People of Doge shall be known by their laughter and their generosity. United under the sacred banner of the Shiba, they performed wonders in the name of kindness. With joyous hearts, they raised treasures to send the Jamaican bobsled team to glory and answered the call of those in need. The People of Doge lived by the divine decree, “Doing Only Good Every day” (D.O.G.E.), spreading light and humor across the lands. Truly, their spirit became the lifeblood of Dogecoin, proving that together, they could achieve the impossible.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 4: The Rise of Doge Under the Doge Father</p>
                        <img className='w-full opacity-50' src={require('../../Assets/ch4.jpg')} />
                        <p className='text-md my-4'>In the age of tweets and memes, a new prophet emerged—Elon Musk, the Doge Father. With his cryptic messages and playful endorsements, he breathed new life into Dogecoin, elevating it from a humble meme to a global sensation. His words, like golden barks, echoed across the internet, causing the faithful to rejoice and the value of Dogecoin to soar. Under his guidance, Dogecoin became not just a currency but a movement, igniting conversations in every corner of the digital realm. Thus began the reign of the Doge Father, a time of humor, chaos, and boundless potential.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 5: Department of Government Efficiency</p>
                        <img className='w-full opacity-60' src={require('../../Assets/ch5.jpg')} />
                        <p className='text-md my-4'>In a bold move to revolutionize governance, the Apex Council for Government Efficiency has been established under the visionary leadership of President Trump. This new committee aims to streamline bureaucracy, cut unnecessary red tape, and ensure that every taxpayer dollar is maximized for impact. With a focus on accountability, innovation, and results, the council is set to redefine how government serves the people—faster, leaner, and sharper than ever before.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 6: National Dogecoin Reserve : Embracing the Legacy, Empowering the Future</p>
                        <img className='w-full opacity-40' src={require('../../Assets/ch6.png')} />
                        <p className='text-md my-4'>The National Dogecoin Reserve stands as a tribute to the enduring legacy of Dogecoin while embracing the limitless creativity of the memecoin universe. Our mission is clear: to establish a decentralized reserve that amplifies Dogecoin’s playful yet powerful spirit, uniting the community in innovation and shared purpose. We believe that memecoins hold the potential to break boundaries, fostering meaningful connections and sparking change within the crypto space. At the National Dogecoin Reserve, we bridge the gap between humor and utility, blending the lighthearted energy of memes with the transformative power of blockchain technology. Through collaboration and community-driven initiatives, we empower Dogecoin enthusiasts and meme lovers, ensuring the vibrant spirit of Dogecoin remains a cornerstone of the crypto world for years to come.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 7: Tokenomics – The Foundation of Dogecoin's Legacy</p>
                        <img className='w-full opacity-90' src={require('../../Assets/ch7.jpg')} />
                        <p className='text-md my-4'>No Taxes: Zero fees or hidden charges.
                            Liquidity Burned: Ensuring stability and trust.
                            Contract Renounced: Power lies with the community, no central control.
                            1 Billion Supply: Abundant, yet valuable—accessible to all.</p>
                    </div>
                    <div className='my-4'>
                        <p className='text-3xl font-bold'>Chapter 8: The Doge Gang and Future</p>
                        <img className='w-full opacity-60' src={require('../../Assets/ch8.jpg')} />
                        <p className='text-md my-4'>The future of Dogecoin is in the hands of a dynamic crew of visionaries. Elon Musk, the Doge Father, continues to fuel the hype, while Vivek Ramaswamy pushes the boundaries of innovation, challenging the status quo. With the bold, unapologetic influence of Donald Trump, this unlikely alliance is paving the way for Dogecoin’s next chapter. Together, they’re combining humor, disruption, and ambition to ensure Dogecoin remains a dominant force in the crypto world.</p>
                    </div>
                </div>
                <img className='max-h-[90vh]' src={require('../../Assets/roll1.png')} />
            </div>
        </div>
    )
}

export default Home