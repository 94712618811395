import React from 'react'

const Purpose = () => {
    return (
        <div className='flex justify-center items-center min-h-[100vh] bg2'>
            <div className='bg-[] relative'>
                <div className='absolute example overflow-auto min-h-[75%] max-h-[75%] w-[80%] top-[15%] p-[2%]  left-1/2 transform -translate-x-1/2  bg-[]'>
                    <div className='my-4'>
                        <p className='text-3xl font-bold text-yellow-500'>Ten Commandments of $BOD</p>
                        {/* <img className='w-full opacity-70' src={require('../../Assets/ch8.jpg')} /> */}
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall NOT jeet</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall NOT say who is Elon</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall pump $BOD</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall NOT pump other memecoins</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall accumulate $BOD</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall make memes for the community</p>
                        <p className='text-md my-4 text-yellow-200'>🗡 Thou shall always have faith</p>
                        

                    </div>
                </div>
                <img className='max-h-[90vh]' src={require('../../Assets/page2.png')} />
            </div>
        </div>
    )
}

export default Purpose